import React from 'react'
import { Link } from 'gatsby'

import styled from 'styled-components'
import { Text } from '@theme-ui/components'
import { rhythm, scale } from '../utils/typography'
import './drawer/drawer.less'
// import "semantic-ui-less/semantic.less"

class Layout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <div>
        <main>{children}</main>
        <footer>
©{new Date().getFullYear()}
, Awesome React Native
</footer>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
    window._mfq = window._mfq || [];
    (function() {
        var mf = document.createElement("script");
        mf.type = "text/javascript"; mf.async = true;
        mf.src = "//cdn.mouseflow.com/projects/266024f6-3dd4-4d09-9a58-0ee970e71364.js";
        document.getElementsByTagName("head")[0].appendChild(mf);
    })();
        `,
          }}
        />
      </div>
    )
  }
}

export default Layout
